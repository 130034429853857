<template>
  <div v-cloak id="sitebody" :class="{ 'mobile': !isDesktop, 'premium': user && user.group !== 1 }">
    <Mobile v-if="!isDesktop" />

    <MovieAds v-if="(!user || user.group === 1)" />

    <!-- <template v-if="(!user || user.group === 1)">
      <div id="SRTB_858931"></div>
    </template> -->

    <!-- <div v-if="isDesktop" id="mp_custom_7260"></div> -->

    <div v-if="AdON && isDesktop" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2270065-4"></div>
      </div>
    </div>

    <div v-if="isDesktop" class="mainmenu">
      <nuxt-link to="/" class="logotype">MANGA<span>ED</span></nuxt-link>
      <Search />
      <div class="profile">
        <!-- <button @click="toggleTheme">Темная тема</button> -->
        <ThemeToggle />
        <span v-if="user" @click="getFavs">Мои списки</span>
        <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">Мои списки</nuxt-link>
        <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
        <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
      </div>
    </div>
    <div v-else class="mainmenu">
      <Search />
      <div class="profile">
        <div style="text-align:left;">
          <nuxt-link to="/" class="logotype">MANGA<span>ED</span></nuxt-link>
        </div>
        <ThemeToggle />
        <!-- <div style="text-align:center;">
          <span v-if="user" @click="getFavs">
            <img src="/static_files/icons/favourite-active.svg" alt="">
          </span>
          <nuxt-link v-else :to="`/signin?ref=${encodeURI($route.fullPath)}`">
            <img src="/static_files/icons/favourite-active.svg" alt="">
          </nuxt-link> 
        </div>-->
        <div style="text-align:right;">
          <nuxt-link v-if="!user" :to="`/signin?ref=${encodeURI($route.fullPath)}`" tag="strong">Войти</nuxt-link>
          <strong v-else @click="showUserMenu = !showUserMenu">Профиль</strong>
        </div>
      </div>
    </div>

    <div v-if="showUserMenu" class="user-menu">
      <div @click="showUserMenu = false" class="close"></div>
      <div class="poster">
        <img :src="user.photo" alt="">
      </div>
      <strong>{{ user.email }}</strong>
      <div>
        Баланс: {{ user.balance }}
        <nuxt-link to="/donate">+</nuxt-link>
      </div>
      <div class="prem">
        <div style="margin-bottom:5px;">
          <b style="color:#000;background-color:#fad200;border-radius:20px;padding:2px 10px;">Premium</b>
        </div>
        
        <div v-if="user.premium_date" style="font-size:1.2rem;">
          до {{ user.premium_date | filterPremDate }}
          <div>
            (осталось {{ Math.round((user.premium_date - Date.now()) / (24 * 60 * 60 * 1000) )}} дн.)
          </div>
          <div>
            <nuxt-link to="/donate">Продлить</nuxt-link>
          </div>
        </div>
        <div v-else>
          не активирован. <nuxt-link to="/donate">Подключить</nuxt-link>
        </div>
      </div>
      <div style="margin-bottom: 15px;">
        <span @click="getFavs" style="cursor: pointer;">
          Списки >
        </span>
      </div>
      <button @click="logout">Выйти</button>
    </div>

    <transition name="toLeft">
      <div v-if="userFavsD" class="user-favs">
        <i @click="userFavsD = null" class="close" />

        <div class="menu">
          <div :style="`width:${7 * 120}px;`">
            <div @click="favCurrent = 'like'" :class="{ active: favCurrent === 'like' }" class="item">Понравились</div>
            <div @click="favCurrent = 'planned'" :class="{ active: favCurrent ===  'planned' }" class="item">Запланировано</div>
            <div @click="favCurrent = 'watching'" :class="{ active: favCurrent ===  'watching' }" class="item">Читаю</div>
            <div @click="favCurrent = 'completed'" :class="{ active: favCurrent ===  'completed' }" class="item">Прочитано</div>
            <div @click="favCurrent = 'hold'" :class="{ active: favCurrent ===  'hold' }" class="item">Отложено</div>
            <div @click="favCurrent = 'dropped'" :class="{ active: favCurrent ===  'dropped' }" class="item">Брошено</div>
            <div @click="favCurrent = 'rewatching'" :class="{ active: favCurrent ===  'rewatching' }" class="item">Перечитываю</div>
          </div>
        </div>
        <div v-if="userFavs.length" class="body">
          <div v-for="item in userFavs" :key="`fav-${item._id}`" class="item">
            <div class="poster">
              <img :src="item.poster" alt="">
            </div>
            <template v-if="item.type">
              <div style="display:inline-block;">
                <strong>{{ item.title_ru }}</strong>
                <span v-if="item.part_id">Глава {{ item.part }}</span>
                <nuxt-link v-if="item.part_id" :to="`/comics/${item.id}/chapter/${item.part_id}?fav=${favCurrent}`"></nuxt-link>
                <nuxt-link v-else :to="`/comics/${item.id}/chapter/0?fav=${favCurrent}`"></nuxt-link>
              </div>
            </template>
            <template v-else>
              <div style="display:inline-block;">
                <strong>{{ item.title }}</strong>
                <nuxt-link :to="`/comics/${postUrl(item.id, item.title)}`"></nuxt-link>
              </div>
            </template>
          </div>
        </div>
        <div v-else class="body">
          Ничего не найдено
        </div>
      </div>
    </transition>

    <template v-if="AdON && (!user || user.group === 1)">
      <div id="yandex_rtb_R-A-2270065-1" class="rsya-block"></div>
      <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template>

    <template v-if="(!user || user.group === 1)">
      <div v-if="!isDesktop" id="adfox_169288458138959483" class="rsya-block"></div>
      <div v-else id="adfox_169288471424829333" class="rsya-block"></div>
      <nuxt-link to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template>

    <!-- <template v-if="(!user || user.group === 1)">
      <div id="SRTB_858932" class="rsya-block"></div>
      <nuxt-link to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template> -->

    <!-- FullScreen -->
    <div id="adfox_16928846514396482"></div>

    <!-- CatFish -->
    <div id="adfox_169288479753292269"></div>

    <nuxt />

    <div id="adfox_169623321234028164"></div>

    <footer class="footer">
      <div class="links">
        <a href="mailto:support@mangaed.ru" target="_blank">
          support@mangaed.ru
        </a>
        <a href="#" target="_blank">
          ВКонтакте
        </a>
        <a href="#" target="_blank">
          Телеграм
        </a>
      </div>
    </footer>

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>

    <FeedbackComponent v-if="feedback" />

    <i onclick="window.scrollTo(0,0)" class="scrollup" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    ThemeToggle: () => import('~/components/ThemeToggle.vue'),
    Search: () => import('~/components/Search.vue'),
    Error: () => import('~/components/Error.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    FeedbackComponent: () => import('~/components/FeedbackComponent.vue'),
    MovieAds: () => import('~/components/MovieAds.vue'),
  },
  filters: {
    filterPremDate(val) {
      const d = new Date(val)
      const day = d.getDate()
      const month = d.getMonth()
      const year = d.getFullYear()
      const hour = d.getHours()
      const min = d.getMinutes()

      const months = [
        'янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
      ]

      return `${day} ${months[month]} ${year} ${hour}:${min}`
    },
  },
  watch: {
    favCurrent(val) {
      if (!this.userFavsD) return
      if (val !== 'like') {
        this.userFavs = this.userFavsD.favs.filter(el => el.action === val)
      } else {
        this.userFavs = this.userFavsD.likes
      }
    },
    userFavsD(val) {
      if (!val) {
        this.favCurrent = 'like'
        this.userFavs = []
      }
    },
    changeRoute() {
      if (this.AdON && !this.isDesktop && (!this.user || this.user.group === 1)) {
        if (this.showFS) return
        const rsyaMobileFS = document.createElement('script')
        rsyaMobileFS.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    type: 'fullscreen',
    blockId: 'R-A-2270065-3'
  })
})`
        document.body.appendChild(rsyaMobileFS)
        this.showFS = true
      }

      this.userFavsD = null
      this.userFavs = []

      document.body.style.overflow = ''

      setTimeout(() => {
        this.lazyLoadImgs()
      }, 400)
    }
  },
  computed: {
    ...mapState(['error', 'feedback', 'isDesktop', 'user']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      AdON: false,
      favCurrent: 'like',
      userFavs: [],
      userFavsD: null,
      showFS: false,
      showVideoAd: false,
      showUserMenu: false,
    }
  },
  mounted() {
    if (this.AdON) this.showAds()

    // setTimeout(() => {
    //   this.showDCAds()
    // }, 5 * 1000)

    this.showDCAds()

    this.lazyLoadImgs()

    window.addEventListener('scroll', this.lazyLoadImgs)

    window.addEventListener('scroll', this.scrollTop)

    window.addEventListener('keyup', this.switchFavs)

    // const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
    // if (prefersDarkScheme.matches) {
    //   document.body.classList.toggle('dark-mode');
    // } else {
    //   const currentTheme = localStorage.getItem('theme');
    //   if (currentTheme === 'dark') {
    //     document.body.classList.toggle('dark-mode');
    //   } else if (currentTheme === 'light') {
    //     document.body.classList.toggle('light-mode');
    //   }
    // }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.switchFavs)
  },
  methods: {
    // toggleTheme() {
    //   const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
    //   if (prefersDarkScheme.matches) {
    //     const theme = document.body.classList.contains('light-mode') ? 'light' : 'dark';
    //     localStorage.setItem('theme', theme);

    //     this.$store.commit('setError', 'Переключите системную тему в режим "Светлая" или "Автоматический"')
    //     setTimeout(() => this.$store.commit('clearError'), 4000)
    //   } else {
    //     const currentTheme = localStorage.getItem('theme');
    //     if (currentTheme === 'light') {
    //       localStorage.setItem('theme', 'dark');
    //     } else if (currentTheme === 'dark') {
    //       localStorage.setItem('theme', 'light');
    //     }
    //     document.body.classList.toggle('dark-mode');
    //   }
    // },
    switchFavs(event) {
      if (event.keyCode === 27) {
        this.userFavsD = null
        this.userFavs = []
      }
    },
    async getFavs() {
      const { data } = await this.$axios.get(`/api/user/favs`)
      // console.log('data', data)
      this.userFavsD = data
      this.userFavs = data.likes
    },
    scrollTop () {
      if (this.$route.name === 'comics-id-chapter-ch') return
      const elem = document.querySelector('.scrollup')
      if (document.documentElement.scrollTop > 1000) {
        elem.classList.add('sticky')
      } else {
        elem.classList.remove('sticky')
      }
    },
    showDCAds() {
      if (!this.user || this.user.group === 1) {
        const dcHeader = document.createElement('script')
        dcHeader.src = 'https://yandex.ru/ads/system/header-bidding.js'
        dcHeader.async = true
        document.head.appendChild(dcHeader)

        const dcJs = document.createElement('script')
        dcJs.innerHTML = `const dcJs = document.createElement('script'); 
dcJs.src = "https://ads.digitalcaramel.com/js/mangaed.ru.js?ts="+new Date().getTime();
dcJs.async = true;
document.head.appendChild(dcJs);`
        dcJs.async = true
        document.head.appendChild(dcJs)

        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        // const clickadilla = document.createElement('script')
        // clickadilla.src = `https://js.wpadmngr.com/static/adManager.js`
        // clickadilla.async = true
        // clickadilla.dataset.admpid = '104319'
        // document.head.appendChild(clickadilla)
            
        setTimeout(() => {
          if (!this.isDesktop) {
            const dcBanner = document.createElement('script')
            dcBanner.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.adfoxCode.createAdaptive({
      ownerId: 332443,
      containerId: 'adfox_169288458138959483',
      params: {
          p1: 'czbrl',
          p2: 'gxnd'
      }
  }, ['phone'], {
      tabletWidth: 830,
      phoneWidth: 480,
      isAutoReloads: false
  })
})
    
setInterval(function(){            
  window.Ya.adfoxCode.reload('adfox_169288458138959483', {onlyIfWasVisible: true})
}, 30000);`
            document.body.appendChild(dcBanner)

            const dcCatfish = document.createElement('script')
            dcCatfish.innerHTML = `
window.yaContextCb.push(()=>{
    Ya.adfoxCode.createAdaptive({
        ownerId: 332443,
        type:'floorAd',
        containerId: 'adfox_169288479753292269',
        params: {
            p1: 'czbrk',
            p2: 'heya'
        }
    }, ['phone'], {
        tabletWidth: 830,
        phoneWidth: 480,
        isAutoReloads: false
    })
})`
            document.body.appendChild(dcCatfish)

            const dcFS = document.createElement('script')
            dcFS.innerHTML = `
setTimeout(() => {
  const dcMakeFullscreen = new DCFullscreen();
  window.yaContextCb.push(() => {
      Ya.adfoxCode.createAdaptive(
          {
              ownerId: 332443,
              fullscreen: true,
              containerId: "adfox_16928846514396482",
              params: {
                  p1: 'czbrj',
                  p2: 'hswb'
              },
              onLoad: function (data) {
                  dcMakeFullscreen.init(data, 5, 5, "adfox_16928846514396482");
              },
              onRender: function () {
                  console.log("render");
              },
              onError: function (error) {
                  console.log(error);
              },
              onStub: function () {
                  console.log("onstub");
              },
          },
          ["phone"],
          {
              tabletWidth: 830,
              phoneWidth: 480,
              isAutoReloads: false,
          }
      );
  });
}, "5000")`
            document.body.appendChild(dcFS)
          } else {
            const dcCatfish = document.createElement('script')
            dcCatfish.innerHTML = `
window.yaContextCb.push(() => {
    Ya.adfoxCode.createAdaptive(
        {
            ownerId: 332443,
            containerId: "adfox_169623321234028164",
            params: {
p1: 'czodo',
p2: 'heyb'
            },
            onRender: function (event) {
                console.log("onRender", event);
                dcMakeCatfish("adfox_169623321234028164");
            },
            onLoad: function (event) {
                console.log("onLoad", event);
            },
            onStub: function (event) {
                console.log("onStub", event);
            },
            onError: function (event) {
                console.log("onError", event);
            },
        },
        ['desktop', 'tablet'],
        {
            tabletWidth: 830,
            phoneWidth: 480,
            isAutoReloads: false,
        }
    );
});`
            // document.body.appendChild(dcCatfish)

            const dcBanner = document.createElement('script')
            dcBanner.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.adfoxCode.createAdaptive({
      ownerId: 332443,
      containerId: 'adfox_169288471424829333',
      params: {
          p1: 'czbro',
          p2: 'gxnc'
      }
  }, ['desktop', 'tablet'], {
      tabletWidth: 830,
      phoneWidth: 480,
      isAutoReloads: false
  })
})
setInterval(function(){            
window.Ya.adfoxCode.reload('adfox_169288471424829333', {onlyIfWasVisible: true})
}, 30000);`
            document.body.appendChild(dcBanner)

            const dcFS = document.createElement('script')
            dcFS.innerHTML = `
window.yaContextCb.push(()=>{
	Ya.Context.AdvManager.render({
		"blockId": "R-A-2729161-5",
		"type": "fullscreen",
		"platform": "desktop"
	})
})`
            document.body.appendChild(dcFS)
          }
        }, 200)
      }
    },
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        this.showVideoAd = true
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-2270065-1',
      blockId: 'R-A-2270065-1'
    })
    })`
          document.body.appendChild(rsyaHorizon)

          if (!this.isDesktop) {
            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      type: 'floorAd',
      blockId: 'R-A-2270065-2'
    })
    })`
            document.body.appendChild(rsyaFooterMobile)
          } else {
            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
    window.yaContextCb.push(()=>{
    Ya.Context.AdvManager.render({
      renderTo: 'yandex_rtb_R-A-2270065-4',
      blockId: 'R-A-2270065-4'
    })
    })`
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    },
    async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
  }
}
</script>
