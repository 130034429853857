import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fa0f2828 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _6902fa9d = () => interopDefault(import('../pages/donate/index.vue' /* webpackChunkName: "pages/donate/index" */))
const _60977aca = () => interopDefault(import('../pages/donate1/index.vue' /* webpackChunkName: "pages/donate1/index" */))
const _7b161d84 = () => interopDefault(import('../pages/popular.vue' /* webpackChunkName: "pages/popular" */))
const _91899b26 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _2e993966 = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _12069968 = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _56417dd4 = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _3441e1b8 = () => interopDefault(import('../pages/wef425g2/index.vue' /* webpackChunkName: "pages/wef425g2/index" */))
const _3bebf97b = () => interopDefault(import('../pages/donate1/success.vue' /* webpackChunkName: "pages/donate1/success" */))
const _ff18466e = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _5e68cc7e = () => interopDefault(import('../pages/wef425g2/comments.vue' /* webpackChunkName: "pages/wef425g2/comments" */))
const _06011f9b = () => interopDefault(import('../pages/wef425g2/desc.vue' /* webpackChunkName: "pages/wef425g2/desc" */))
const _5a1557f9 = () => interopDefault(import('../pages/wef425g2/donate.vue' /* webpackChunkName: "pages/wef425g2/donate" */))
const _71d8ace2 = () => interopDefault(import('../pages/wef425g2/feedback.vue' /* webpackChunkName: "pages/wef425g2/feedback" */))
const _ae39b4ae = () => interopDefault(import('../pages/wef425g2/kino.vue' /* webpackChunkName: "pages/wef425g2/kino" */))
const _22deca51 = () => interopDefault(import('../pages/comics/_id/index.vue' /* webpackChunkName: "pages/comics/_id/index" */))
const _6fa4fc81 = () => interopDefault(import('../pages/comics/_id/chapter/_ch.vue' /* webpackChunkName: "pages/comics/_id/chapter/_ch" */))
const _12c331f7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/copyright",
    component: _fa0f2828,
    name: "copyright"
  }, {
    path: "/donate",
    component: _6902fa9d,
    name: "donate"
  }, {
    path: "/donate1",
    component: _60977aca,
    name: "donate1"
  }, {
    path: "/popular",
    component: _7b161d84,
    name: "popular"
  }, {
    path: "/privacy",
    component: _91899b26,
    name: "privacy"
  }, {
    path: "/signin",
    component: _2e993966,
    name: "signin"
  }, {
    path: "/success",
    component: _12069968,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _56417dd4,
    name: "termsofuse"
  }, {
    path: "/wef425g2",
    component: _3441e1b8,
    name: "wef425g2"
  }, {
    path: "/donate1/success",
    component: _3bebf97b,
    name: "donate1-success"
  }, {
    path: "/profile/favs",
    component: _ff18466e,
    name: "profile-favs"
  }, {
    path: "/wef425g2/comments",
    component: _5e68cc7e,
    name: "wef425g2-comments"
  }, {
    path: "/wef425g2/desc",
    component: _06011f9b,
    name: "wef425g2-desc"
  }, {
    path: "/wef425g2/donate",
    component: _5a1557f9,
    name: "wef425g2-donate"
  }, {
    path: "/wef425g2/feedback",
    component: _71d8ace2,
    name: "wef425g2-feedback"
  }, {
    path: "/wef425g2/kino",
    component: _ae39b4ae,
    name: "wef425g2-kino"
  }, {
    path: "/comics/:id",
    component: _22deca51,
    name: "comics-id"
  }, {
    path: "/comics/:id?/chapter/:ch?",
    component: _6fa4fc81,
    name: "comics-id-chapter-ch"
  }, {
    path: "/",
    component: _12c331f7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
