<template>
  <div class="cln" :class="{ 'mobile': !isDesktop, 'premium': user && user.group !== 1 }">
    <Mobile v-if="!isDesktop" />

    <MovieAds v-if="(!user || user.group === 1)" />

    <!-- <template v-if="(!user || user.group === 1)">
      <div id="SRTB_858931"></div>
    </template> -->

    <!-- <div v-if="isDesktop" id="mp_custom_7260"></div> -->

    <div v-if="AdON && isDesktop" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2270065-4"></div>
      </div>
    </div>

    <template v-if="AdON && (!user || user.group === 1)">
      <div id="yandex_rtb_R-A-2270065-1" class="rsya-block"></div>
      <nuxt-link v-if="!user || (user.group !== 777 && user.group !== 0)" to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template>

    <template v-if="(!user || user.group === 1)">
      <div v-if="!isDesktop" id="adfox_169288458138959483" class="rsya-block"></div>
      <div v-else id="adfox_169288471424829333" class="rsya-block"></div>
      <nuxt-link to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template>

    <!-- <template v-if="(!user || user.group === 1)">
      <div id="SRTB_858932" class="rsya-block"></div>
      <nuxt-link to="/donate" tag="div" class="close-ad">
        Отключить рекламу
      </nuxt-link>
    </template> -->

    <p class="ad">
      А еще у нас много классных аниме на русском, заходи смотри
      <a href="https://anime777.ru" target="_blank">ANIME777</a>
      бесплатно в HD.
    </p>

    <!-- FullScreen -->
    <div id="adfox_16928846514396482"></div>

    <!-- CatFish -->
    <div id="adfox_169288479753292269"></div>

    <nuxt />

    <!-- <div id="yandex_rtb_R-A-2270065-6"></div> -->
    <Error v-if="error" :error="error" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    Mobile: () => import('~/components/Mobile.vue'),
    Error: () => import('~/components/Error.vue'),
    MovieAds: () => import('~/components/MovieAds.vue'),
  },
  watch: {
    changeRoute(val) {
      if (this.AdON && !this.isDesktop && (!this.user || this.user.group === 1)) {
        if (this.showFS) return
        const rsyaMobileFS = document.createElement('script')
        rsyaMobileFS.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    type: 'fullscreen',
    blockId: 'R-A-2270065-3'
  })
})`
        document.body.appendChild(rsyaMobileFS)
        this.showFS = true
      }
    },
  },
  computed: {
    ...mapState(['error', 'user', 'isDesktop']),
    changeRoute() {
      return this.$route.fullPath
    },
  },
  data() {
    return {
      AdON: false,
      showFS: false,
      showVideoAd: true,
      showAdBlock: false,
    }
  },
  mounted() {
    if (this.AdON) this.showAds()

    // setTimeout(() => {
    //   this.showDCAds()
    // }, 5 * 1000)

    this.showDCAds()

    window.addEventListener('scroll', this.lazyLoadImgs)
    this.lazyLoadImgs()
  },
  methods: {
    showDCAds() {
      if (!this.user || this.user.group === 1) {
        const dcHeader = document.createElement('script')
        dcHeader.src = 'https://yandex.ru/ads/system/header-bidding.js'
        dcHeader.async = true
        document.head.appendChild(dcHeader)

        const dcJs = document.createElement('script')
        dcJs.innerHTML = `const dcJs = document.createElement('script'); 
dcJs.src = "https://ads.digitalcaramel.com/js/mangaed.ru.js?ts="+new Date().getTime();
dcJs.async = true;
document.head.appendChild(dcJs);`
        dcJs.async = true
        document.head.appendChild(dcJs)

        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)
        
        // const clickadilla = document.createElement('script')
        // clickadilla.src = `https://js.wpadmngr.com/static/adManager.js`
        // clickadilla.async = true
        // clickadilla.dataset.admpid = '104319'
        // document.head.appendChild(clickadilla)

        setTimeout(() => {
          if (!this.isDesktop) {
            const dcBanner = document.createElement('script')
            dcBanner.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.adfoxCode.createAdaptive({
      ownerId: 332443,
      containerId: 'adfox_169288458138959483',
      params: {
          p1: 'czbrl',
          p2: 'gxnd'
      }
  }, ['phone'], {
      tabletWidth: 830,
      phoneWidth: 480,
      isAutoReloads: false
  })
})
    
setInterval(function(){            
  window.Ya.adfoxCode.reload('adfox_169288458138959483', {onlyIfWasVisible: true})
}, 30000);`
            document.body.appendChild(dcBanner)

            const dcCatfish = document.createElement('script')
            dcCatfish.innerHTML = `
window.yaContextCb.push(()=>{
    Ya.adfoxCode.createAdaptive({
        ownerId: 332443,
        type:'floorAd',
        containerId: 'adfox_169288479753292269',
        params: {
            p1: 'czbrk',
            p2: 'heya'
        }
    }, ['phone'], {
        tabletWidth: 830,
        phoneWidth: 480,
        isAutoReloads: false
    })
})`
            document.body.appendChild(dcCatfish)
            
            const dcFS = document.createElement('script')
            dcFS.innerHTML = `
setTimeout(() => {
  const dcMakeFullscreen = new DCFullscreen();
  window.yaContextCb.push(() => {
      Ya.adfoxCode.createAdaptive(
          {
              ownerId: 332443,
              fullscreen: true,
              containerId: "adfox_16928846514396482",
              params: {
                  p1: 'czbrj',
                  p2: 'hswb'
              },
              onLoad: function (data) {
                  dcMakeFullscreen.init(data, 5, 5, "adfox_16928846514396482");
              },
              onRender: function () {
                  console.log("render");
              },
              onError: function (error) {
                  console.log(error);
              },
              onStub: function () {
                  console.log("onstub");
              },
          },
          ["phone"],
          {
              tabletWidth: 830,
              phoneWidth: 480,
              isAutoReloads: false,
          }
      );
  });
}, "5000")`
            document.body.appendChild(dcFS)
          } else {
            const dcCatfish = document.createElement('script')
            dcCatfish.innerHTML = `
window.yaContextCb.push(() => {
    Ya.adfoxCode.createAdaptive(
        {
            ownerId: 332443,
            containerId: "adfox_169623321234028164",
            params: {
p1: 'czodo',
p2: 'heyb'
            },
            onRender: function (event) {
                console.log("onRender", event);
                dcMakeCatfish("adfox_169623321234028164");
            },
            onLoad: function (event) {
                console.log("onLoad", event);
            },
            onStub: function (event) {
                console.log("onStub", event);
            },
            onError: function (event) {
                console.log("onError", event);
            },
        },
        ['desktop', 'tablet'],
        {
            tabletWidth: 830,
            phoneWidth: 480,
            isAutoReloads: false,
        }
    );
});`
            // document.body.appendChild(dcCatfish)
            
            const dcBanner = document.createElement('script')
            dcBanner.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.adfoxCode.createAdaptive({
      ownerId: 332443,
      containerId: 'adfox_169288471424829333',
      params: {
          p1: 'czbro',
          p2: 'gxnc'
      }
  }, ['desktop', 'tablet'], {
      tabletWidth: 830,
      phoneWidth: 480,
      isAutoReloads: false
  })
})
setInterval(function(){            
window.Ya.adfoxCode.reload('adfox_169288471424829333', {onlyIfWasVisible: true})
}, 30000);`
            document.body.appendChild(dcBanner)

            const dcFS = document.createElement('script')
            dcFS.innerHTML = `
window.yaContextCb.push(()=>{
	Ya.Context.AdvManager.render({
		"blockId": "R-A-2729161-5",
		"type": "fullscreen",
		"platform": "desktop"
	})
})`
            document.body.appendChild(dcFS)
          }
        }, 200)
      }
    },
    showAds() {
      if (!this.user || this.user.group === 1) {
        setInterval(() => {
          this.showVideoAd = true
        }, 5 * 60 * 1000)

        this.showVideoAd = true
        
        const rsya1 = document.createElement('script')
        rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
        document.head.appendChild(rsya1)

        const rsya2 = document.createElement('script')
        rsya2.src = `https://yandex.ru/ads/system/context.js`
        rsya2.async = true
        document.head.appendChild(rsya2)

        setTimeout(() => {
          const rsyaHorizon = document.createElement('script')
          rsyaHorizon.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-2270065-1',
  blockId: 'R-A-2270065-1'
})
})`
          document.body.appendChild(rsyaHorizon)

          if (!this.isDesktop) {
            const rsyaFooterMobile = document.createElement('script')
            rsyaFooterMobile.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.render({
  type: 'floorAd',
  blockId: 'R-A-2270065-2'
})
})`
            document.body.appendChild(rsyaFooterMobile)

//         const rsyaLenta = document.createElement('script')
//         rsyaLenta.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.renderFeed({
//     renderTo: 'yandex_rtb_R-A-2270065-6',
//     blockId: 'R-A-2270065-6'
//   })
// })`
//         document.body.appendChild(rsyaLenta)
          } else {
            const rsyaDesktopVideoAd = document.createElement('script')
            rsyaDesktopVideoAd.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-2270065-4',
  blockId: 'R-A-2270065-4'
})
})`
            document.body.appendChild(rsyaDesktopVideoAd)
          }
        }, 200)
      }
    }
  }
}
</script>

<style lang="stylus">
p.ad
  padding: 10px 20px 10px
  color: #ddd
  background-color: rgba(255, 255, 255, 0.1)
  a
    color: orange
    &:hover
      color: red
</style>
